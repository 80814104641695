.mapa {
  width: 500px;
  height: 300px;
  border: 0px;
}

/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1583.1782694248416!2d-72.36290044784376!3d-37.475911921868935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966bdd33d93bd7a9%3A0x6a09b11acafe619a!2zQWxjw6F6YXIgMzY0LCBMb3MgQW5nZWxlcywgTG9zIMOBbmdlbGVzLCBCw61vIELDrW8!5e0!3m2!1ses-419!2scl!4v1612899503371!5m2!1ses-419!2scl" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>*/

.v_container {
  display: flex;
  flex-direction: column;
  font-size: 130%;
  width: 50vw;
  min-height: 100vh;
  background-color: white;
  text-align: justify;
  padding: 100px;
  align-items: center;
  justify-content: center;
}
.layout_fondo {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.inicio_fondo {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 60vh;
}

.inicio_fondo_logo {
  display: flex;
  flex: 1;
  max-height: 150;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.inicio_logo {
  max-width: 160px;
  margin-left: 60px;
  margin-right: 60px;
}

.inicio_logo_texto {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
}

.inicio_logo_subtexto {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: large;
}

.inicio_phone {
  max-width: 35px;
  padding-right: 10px;
}

.inicio_wtsp {
  max-width: 45px;
  padding-right: 10px;
}

.inicio_gmail {
  min-width: 40px;
  max-width: 40px;
  padding-right: 10px;
}

.inicio__barra_texto {
  color: white;
  font-size: large;
  font-weight: bold;
}

.inicio_pv_noticias {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20vw;
  background-color: #7DBB5B;
  padding-left: 20vw;
  flex: 1;
}

.inicio_pv_galeria {
  padding-top: 40px;
  background-color: #A2CCEA;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20vw;
  padding-left: 20vw;
  justify-content: center;
  flex: 1;
}

.inicio_noticias {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
  min-height: 60vh;
}

.galeria_fondo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 60vh;
  padding-bottom: 100px;
  background-color: #A2CCEA;
  max-width: 100%;
  flex-wrap: wrap;
}

.galeria_evento_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: fit-content;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  height: fit-content;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 4px 4px 30px rgb(122, 122, 122);
}

.galeria_evento_imagen {
  width: 350px;
}

.galeria_imagen_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
}

.galeria_imagen {
  width: 350px;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 60px;
  border: 10px solid #D4E8A1;
}

.noticias_fondo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 60vh;
  background-color: #7DBB5B;
  padding: 100px;
}

.noticias_noticia_container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  background-color: white;
  height: 520px;
  max-width: 320px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 60px;
  overflow: hidden;
  box-shadow: 4px 4px 30px rgb(122, 122, 122);
  padding: 10px;
  border-radius: 5px;
}

.noticias_noticia_container2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.noticia_container {
  font-size: 130%;
  min-width: 320px;
  height: fit-content;
  padding: 10px;
  background-color: white;
  box-shadow: 4px 4px 30px rgb(122, 122, 122);
  border-radius: 10px;
}

.noticia_imagen {
  height: 250px;
  margin-right: 30px;
  margin-bottom: 20px;
  float: left;
}

.noticias_imagen {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.bg-prueba {
  background: linear-gradient(to right, #F7B10C, #ffc132);
  color: white;
  font-size: large;
  font-weight: bold;
}

.inicio_carrusel {
  flex: 1;
  height: 30vw;
  min-height: 356px;
}

.negro {
  display: flex;
  flex: 1;
  height: 30vw;
  min-height: 356px;
  background-color: black;
}

.cnegro {
  color: black;
}

.pie_pagina_fondo {
  display: flex;
  flex: 1;
  background-color: black;
  min-height: 70vh;
}

.pie_pagina_fondo_sub1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 90%;
  background-color: black;
}

.pie_pagina_fondo_sub2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: black;
  align-items: center;
  justify-content: space-between;
}

.pie_pagina_fondo_sub3 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.container_im_noticia:hover {
  transform: scale(1.3);
  overflow: hidden;
}

.container_img_noticia {
  overflow: hidden;
}


.inicio_adm {
  display: flex;
  min-height: 60vh;
  background-color: aqua;
  flex-direction: column;
  align-items: center;
}

.modulo_adm {
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  margin: 30px;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 4px 4px 30px rgb(122, 122, 122);
}

.fondo_noticias_adm {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 60vh;
  align-items: center;
}

.tabla_adm {
  margin-top: 30px;
}

.editar_noticia {
  width: 90vw;
}

.noticia_preview_container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  height: 550px;
  transform: scale(0.85);
  min-width: 360px;
  max-width: 360px;
  box-shadow: 4px 4px 30px rgb(122, 122, 122);
  padding: 10px;
  border-radius: 5px;
}

.fondo_galeria_adm {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 60vh;
  align-items: center;
}

.carrusel_adm {
  display: flex;
  min-height: 60vh;
  flex-direction: column;
  justify-content: space-between;
}

.carrusel_adm_div1 {
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.carrusel_adm_div2 {
  display: flex;
  height: fit-content;
  width: 100%;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.app-image-gallery {
  width: 90%;
}

.utiles_fondo {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
}

.pv_lutiles_pdf {
  display: flex;
  width: 70%;
  height: 600px;
}

.imagenes_fondo {
  background-color: #A2CCEA;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.personal_fondo {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: aliceblue;
}

.personal_fila {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 4px 4px 30px rgb(122, 122, 122);
  margin-top: 30px;
  height: fit-content;
}

.personal_box {
  display: flex;
  margin: 30px;
  flex-direction: column;
  align-items: center;
  width: 300px;
  justify-content: center;
}

.v_fondo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url('http://centroangelitos.cl/i.png');
  flex-wrap: wrap;
  min-height: 60vh;
}



.mvs_contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 300px;
  background-color: #41B9E6;
}

.mvs_texto-encima {
  position: absolute;
  top: 10px;
  left: 10px;
}

.mvs_centrado {
  position: absolute;
  top: 50%;
  color: white;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1000px) {
  .galeria_evento_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: fit-content;
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    height: fit-content;
    border-radius: 6px 6px 6px 6px;
    box-shadow: 4px 4px 30px rgb(122, 122, 122);
  }
  .galeria_evento_imagen {
    max-width: 300px;
  }
  .container_im_noticia:hover {
    transform: scale(1.3);
    overflow: hidden;
  }
  .container_img_noticia {
    overflow: hidden;
  }
  .noticia_imagen {
    margin: 0px;
    height: auto;
    width: 100%;
  }
  .inicio_fondo_logo {
    display: flex;
    flex: 1;
    min-height: 200px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .inicio_logo_subtexto {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: large;
  }
  .inicio_logo {
    max-width: 160px;
    margin-left: 30px;
  }
  .inicio_logo_texto {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding-bottom: 30px;
  }
  .noticias_noticia_container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 550px;
    max-width: 360px;
    min-width: 300px;
    overflow: hidden;
    margin-top: 60px;
    box-shadow: 4px 4px 30px rgb(122, 122, 122);
    padding: 10px;
    border-radius: 5px;
  }
  .inicio_pv_noticias {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .inicio_noticias {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100vw;
    margin-bottom: 60px;
  }
  .pie_pagina_fondo {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: black;
    height: 1500px;
  }
  .pie_pagina_fondo_sub1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: small;
    background-color: black;
    flex: 2;
  }
  .pie_pagina_fondo_sub2 {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
    background-color: black;
  }
  .pie_pagina_fondo_sub3 {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: center;
    background-color: black;
  }
  .v_container {
    display: flex;
    flex-direction: column;
    font-size: 130%;
    width: 90vw;
    min-height: 100vh;
    padding: 30px;
    align-items: center;
    background-color: white;
    justify-content: center;
  }
}
